var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputUUIDProvider", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var uuid = ref.uuid
          return [
            _c(_vm.styleComponent, {
              tag: "Component",
              attrs: {
                focused: _vm.isFocused,
                error: _vm.isError,
                "data-cy": _vm.dataCy,
                disabled: _vm.disabled,
                alignment: _vm.alignment,
                size: _vm.size,
                height: _vm.height,
                "details-label": _vm.informationLabel
              },
              on: { mousedown: _vm.onMouseDown, mouseup: _vm.onMouseUp },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function() {
                      return [
                        _c(
                          "InputController",
                          { ref: "activator" },
                          [
                            _vm._t("prepend"),
                            _vm._v(" "),
                            _c(
                              "input",
                              _vm._b(
                                {
                                  ref: "input",
                                  class: _vm.classes,
                                  attrs: {
                                    id: uuid,
                                    placeholder: _vm.placeholderValue,
                                    autocomplete: "on",
                                    disabled: _vm.disabled,
                                    "aria-label": _vm.label || "no description"
                                  },
                                  domProps: { value: _vm.value },
                                  on: {
                                    input: _vm.onValueChange,
                                    focus: _vm.onFocus,
                                    blur: _vm.onBlur
                                  }
                                },
                                "input",
                                Object.assign({}, _vm.input),
                                false
                              )
                            ),
                            _vm._v(" "),
                            _vm.label
                              ? _c("InputLabel", {
                                  attrs: {
                                    for: uuid,
                                    required: _vm.required,
                                    size: _vm.size,
                                    floating: _vm.isFocused || !_vm.isEmpty,
                                    focused: _vm.isFocused,
                                    disabled: _vm.disabled,
                                    error: _vm.isError,
                                    label: _vm.label
                                  }
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._t("append"),
                            _vm._v(" "),
                            _vm.isError
                              ? _c("ErrorHint", {
                                  attrs: { hint: _vm.errorMessages }
                                })
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "details",
                    fn: function() {
                      return [_vm._t("details")]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }